@each $name, $px in $resolutions {
    @media (min-width: #{$px}px) {
        @each $size, $percent in $sizes {
            .box-#{$name}-#{$size} {
                display: inline-block;
                width: #{$percent};
                vertical-align: top;
            }

            .offset-#{$name}-#{$size} {
                margin-left: #{$percent};
            }

            @each $num, $px in $padmar {
                .box-#{$name}-#{$size}.px-#{$name}-#{$num}, .box-#{$name}-#{$size}.mx-#{$name}-#{$num} {
                    width: calc(#{$percent} - #{$px} - #{$px});
                }
        
                .box-#{$name}-#{$size}.px-#{$name}-#{$num}.mx-#{$name}-#{$num} {
                    width: calc(#{$percent} - #{$px} - #{$px} - #{$px} - #{$px});
                }
        
                .box-#{$name}-#{$size}.pl-#{$name}-#{$num}, .box-#{$name}-#{$size}.pr-#{$name}-#{$num}, .box-#{$name}-#{$size}.ml-#{$name}-#{$num}, .box-#{$name}-#{$size}.mr-#{$name}-#{$num} {
                    width: calc(#{$percent} - #{$px});
                }
        
                .box-#{$name}-#{$size}.pl-#{$name}-#{$num}.pr-#{$name}-#{$num}, 
                .box-#{$name}-#{$size}.pl-#{$name}-#{$num}.ml-#{$name}-#{$num},
                .box-#{$name}-#{$size}.pl-#{$name}-#{$num}.mr-#{$name}-#{$num},
                .box-#{$name}-#{$size}.pr-#{$name}-#{$num}.ml-#{$name}-#{$num},
                .box-#{$name}-#{$size}.pr-#{$name}-#{$num}.mr-#{$name}-#{$num} {
                    width: calc(#{$percent} - #{$px} - #{$px});
                }
            }
        }

        @each $display in $displays {
            .#{$name}-#{$display} {
                display: #{$display};
            }
        }

        .#{$name}-default {
            display: inline-block;
        }

        @each $align in $aligns {
            .text-#{$name}-#{$align} {
                text-align: #{$align};
            }
        }

        // padding, margin
        @each $num, $px in $padmar {
            // padding
            .px-#{$name}-#{$num} {
                padding-left: $px;
                padding-right: $px;
            }

            .py-#{$name}-#{$num} {
                padding-top: $px;
                padding-bottom: $px;
            }

            .pt-#{$name}-#{$num} {
                padding-top: $px;
            }

            .pb-#{$name}-#{$num} {
                padding-bottom: $px;
            }

            .pl-#{$name}-#{$num} {
                padding-left: $px;
            }

            .pr-#{$name}-#{$num} {
                padding-right: $px;
            }

            // margin
            .mx-#{$name}-#{$num} {
                margin-left: $px;
                margin-right: $px;
            }

            .my-#{$name}-#{$num} {
                margin-top: $px;
                margin-bottom: $px;
            }

            .mt-#{$name}-#{$num} {
                margin-top: $px;
            }

            .mb-#{$name}-#{$num} {
                margin-bottom: $px;
            }

            .ml-#{$name}-#{$num} {
                margin-left: $px;
            }

            .mr-#{$name}-#{$num} {
                margin-right: $px;
            }
        }

        .pull-#{$name}-right {
            float: right;
        }
        
        .pull-#{$name}-left {
            float: left;
        }
    }
}