input[type=checkbox]:checked:after {
    visibility: hidden;
}

input[type=checkbox]:before {
    visibility: hidden;
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: white;
    color: black;
    font-weight: 700;
}


.listbox {
    visibility: hidden;
}

select[multiple],
select[size] {
    height: 0;
}

.info {
    margin-top: 20px;
    color: #333;
}

.bold-cell {
    font-weight: 900 !important;
}

.MuiDataGrid-columnHeaderTitle.css-1jbbcbn-MuiDataGrid-columnHeaderTitle{
    font-weight: 900 !important;
}

.icon-cell{
    font-style: italic;
    font-weight: 900 ;
    font-size: large;
}
