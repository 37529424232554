.returnIcon {
    margin-right: 15px;
    color: #333;
    fill: #333;
}

.returnLabel {
    margin-bottom: 20px;
    color: #333;
}

.returnLabel:hover {
    font-weight: bold;
    color: #111;
}

.returnLabel.returnIcon:hover {
    fill: #111;
    font-weight: bold;
}

.flag-title-image {
    width: 100px;
    height: auto;
    border: 1.5px solid black;
}

.underLabel {
    margin-top: 150px;
}

.revertTabs {
    flex-direction: row-reverse !important;
}

.topItems {
    font-size: 16px;
}

.labelIcon {
    font-size: 25px;
    color: #6146d9;
    font-weight: bold;
    margin-right: 15px;
}

.trendLabel {
    color: #000;
    font-weight: 400;
}

.verticalLine {
    border-left: 1px solid;
    border-color: rgba(0, 0, 0, 0.12);
}