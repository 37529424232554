
.box-danger {
    padding: 4px 8px;
    color: rgb(183, 33, 54);
    font-size: 0.75rem;
    background-color: rgba(255, 72, 66, 0.16);
    font-weight: 700;
    height: 24px;
    width: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
}

.box-success {
    padding: 4px 8px;
    color: rgb(34, 154, 22);
    font-size: 0.75rem;
    background-color: rgba(84, 214, 44, 0.16);
    font-weight: 700;
    height: 24px;
    width: 60px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
}

.largebox-success {

    padding: 8px 12px;
    color: rgb(34, 154, 22);
    background-color: rgba(84, 214, 44, 0.16);
    font-weight: 700;
    height: 24px;
    width: 60px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
}

.largebox-danger {

    padding: 8px 12px;
    color: rgb(183, 33, 54);
    background-color: rgba(255, 72, 66, 0.16);
    font-weight: 700;
    height: 24px;
    width: 60px;
    font-size: 14px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 6px;
}

.cardName {
    height: 64px !important;
    margin-bottom: 10px;
    margin-top: 20px;
}

.fingerprintIcon {
    position: absolute;
    margin-left: 200px;
    margin-top: -40px;
    fill: white;
    color: white;
    background-color: #00a0dc;
    border-radius: 50%;
    padding: 10px;
    width: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    font-size: 20px;
    padding-top: -3px;
    /*    border: 1px solid #111;*/
}

#selectCriminalOffenceFilter{
    /*height: 20px !important;*/
    padding-top: 12px !important;
    padding-bottom: 12px !important;
 
}




@media (max-width: 1300px) {
    .cardContainer {
        flex-direction: column !important;

    }

    .statCard {
        margin-bottom: 30px;
        margin-top: 30px;

    }
}