.graph_wrapper {
    position: relative;
    width: 100%;
    height: auto;
}

/*.graph_wrapper .loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
}
.loading > h2 {
    color: white;
}*/

.graph_wrapper .loading {
    position: absolute;
    top: 0;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 20, 0.7);
    color: #FFF;
    z-index: 99;
}
@keyframes search {
    0% { margin-right: 30px; padding-left: 0px; }
    45% { margin-right: 0px; padding-left: 30px; }
    50% { margin-right: 0px; padding-left: 30px; }
    95% { margin-right: 30px; padding-left: 0px; }
    100% { margin-right: 30px; padding-left: 0px; }
}
.graph_wrapper .loading div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.graph_wrapper .loading div svg {
    margin-right: 0px;
    padding-left: 30px;
    animation: search 2s infinite;
}



.graph-options {
    display: flex;
}


/* robi mi to problem pri checkboxoch v hladani - pridaj si tam classu alebo z toho sprav modularne css */
/*input[type=checkbox] {
    position: absolute !important;
}*/
.graph-options .MuiRadio-root svg {
    top: 0 !important;
}