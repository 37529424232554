.modal {
    position: absolute;
    max-width: calc(100% - 40px);
    background-color: #FFF;
    box-shadow: 0px 0px 15px 0px #113;
    border-radius: 5px;
    padding: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    overflow-y: auto;
}

.head {
    position: relative;
    border-bottom: 1px solid #003;
    padding-bottom: 2px;
}

.title {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 42px);
    padding: 0px 8px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
}

.close {
    position: relative;
    top: -5px;
    right: -25px;
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 22px;
    font-size: 19px;
    font-weight: bold;
    color: #EE0033;
    border: 1px solid #EE0033;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.close svg {
    position: absolute;
    top: 1px;
    left: 1px;
}

.close:hover {
    color: #BB0033;
    border-color: #BB0033;
}

.content {
    padding: 8px;
}