.filter-info {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 20px;
    font-weight: 500;
    line-height: 70px;
    margin-left: 20px;
    font-style: italic;
    width: 180px;
    display: inline-block;
}