.flag img {
    width: 30px;
    height: 20px;
    margin: auto;
    border: 1.5px solid black;
    object-fit: cover;
}






