.searchLink {
    display: block;
    padding: 5px;
    font-size: 18px;
    transition: 0.3s;
    text-align: center;
    border-radius: 5px;
    margin: 3px;
}

.searchLink:hover {
    color: #336;
    background-color: #F3F3F3;
}

.login {
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
    font-weight: normal;
}

.reg_login_table {
    display: table;
    margin: 0 auto;
    border-collapse: separate;
    border-spacing: 15px 5px;
    text-align: left;
}

.linkName {
    width: 200px;
}

.linksName {
    display: inline-block;
    vertical-align: top;
}
.buttonMyLinks {
    display: inline-block;
}

.errorCaption {
    font-size: 20px;
    margin-top: 20px;
}

.errorSmile {
  margin: 0 auto;
  display: block;
  font-size: 150px;
}

.loading {
    position: absolute;
    top: 115px;
    left: 0px;
    width: 100%;
    height: calc(100% - 168px);
    text-align: center;
    background-color: rgba(0, 0, 20, 0.7);
    color: #FFF;
    z-index: 99;
}

.loading div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.loading div svg {
    margin-right: 0px;
    padding-left: 30px;
    animation: search 2s infinite;
}

.dot {
    height: 25px;
    width: 25px;
    background-color: black;
    border-radius: 50%;
    display: inline-block;
}

.legend {
    text-align: left;
}

.legend h5 {
    display: inline-block;
    margin-bottom: 0px;
    vertical-align: super;
    margin-left: 15px;
}

.vertAlignBottom {
    vertical-align: bottom;
}

.expressionButton {
    display: inline-block;
    padding: 2px 5px;
    margin: 3px;
    border: 1px solid #336;
    background-color: #EEE;
    font-size: 14px;
    border-radius: 5px;
    transition: 0.3s;
}

.expressionFakeButton {
    display: inline-block;
    padding: 2px 5px;
    margin: 3px;
    border: 1px solid #336;
    background-color: #EEE;
    font-size: 14px;
    border-radius: 5px;
    transition: 0.3s;
}

.expressionButton:hover {
    cursor: pointer;
    background-color: #DDD;
}

.expressionRemove {
    position: relative;
    top: 2px;
    margin: 0px 3px;
    font-size: 20px;
    color: #CC3333;
    cursor: pointer;
    transition: 0.3s;
}

.expressionRemove:hover {
    color: #770000;
}

.expressionCopy {
    position: relative;
    top: 2px;
    margin: 0px 3px;
    font-size: 20px;
    color: #3333CC;
    cursor: pointer;
    transition: 0.3s;
}

.expressionCopy:hover {
    color: #000077;
}


.exp_table {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
}

.exp_table tr {
    background-color: #EEE;
}

.exp_table tr:nth-child(2n) {
    background-color: #FFF;
}

.exp_table td {
    text-align: center;
}

.users {
    width: 100%;
    border-spacing: 0px;
    border-collapse: collapse;
    font-size: 18px;
}

.users tr {
    background-color: #EEE;
    text-align: center;
}

.users tr:nth-child(2n) {
    background-color: #FFF;
}

.users th {
    background-color: #DDD;
    padding: 5px;
}

.users td {
    padding: 5px;
}

.usersRemove {
    position: relative;
    top: 2px;
    margin: 0px 3px;
    font-size: 20px;
    color: #CC3333;
    cursor: pointer;
    transition: 0.3s;
}

.usersRemove:hover {
    color: #770000;
}

.tableLine:nth-child(2n) { background: rgb(221, 221, 221); }
