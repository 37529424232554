.cards-box {
    display: flex;
    flex-wrap: wrap;
}

.card {
    background-color: #FFF;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 2px 2px 0px #222;
    transform: scale(1.0);
    transition: 0.3s;
    -cursor: pointer;
}

.card:hover {
    transform: scale(1.03);
}

.card-link {
    width: 100%;
    height: 100%;
}

.card-link-icon {
    font-size: 44px;
}

.card-link-text {
    margin-top: 10px;
    font-size: 28px;
    text-shadow: 2px 2px 0px #EEE;
}