.label-states {
    font-size: 16px;
    font-style: italic;
    margin-left: 8px;
}

.prediction-param-label {
    font-size: 16px;
    vertical-align: middle;
    margin-right: 20px;
    font-weight: 500;
    line-height: 70px;
    margin-left: 8px;
    font-style: italic;
    width: 180px;
    display: inline-block;
}