.graph {
    position: relative;
    display: 'inline-block'; 
    vertical-align: 'top';
    margin-bottom: 40px;
    margin-top: 20px;
}

@keyframes search {
    0% { margin-right: 30px; padding-left: 0px; }
    45% { margin-right: 0px; padding-left: 30px; }
    50% { margin-right: 0px; padding-left: 30px; }
    95% { margin-right: 30px; padding-left: 0px; }
    100% { margin-right: 30px; padding-left: 0px; }
}

.loading {
    position: absolute;
    top: 48px;
    left: 0px;
    width: 100%;
    height: calc(100% - 48px);
    text-align: center;
    background-color: rgba(0, 0, 20, 0.7);
    color: #FFF;
    z-index: 9999;
}

.loading div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.loading div svg {
    margin-right: 0px;
    padding-left: 30px;
    animation: search 2s infinite;
}

.dataset {
    border-radius: 5px;
    border: 1px solid #001
}

.dataset .title {
    font-size: 16px;
}

.close, .edit, .share {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 22px;
    font-size: 19px;
    font-weight: bold;
    color: #EE0033;
    border: 1px solid #EE0033;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    z-index: 5;
}

.close svg, .edit svg, .share svg {
    position: absolute;
    top: 2px;
    left: 2px;
}

.close:hover {
    color: #BB0033;
    border-color: #BB0033;
}

.edit {
    color: #112211;
    border-color: #112211;
    bottom: auto;
    top: 5px;
    right: 35px;
}

.share {
    color: #5b97d9;
    border-color: #5b97d9;
    bottom: auto;
    top: 5px;
    right: 65px;
}

.share:hover {
    color: #4285cf;
    border-color: #4285cf;
}

.closeTop {
    bottom: auto;
    top: 5px;
}