.logo {
    font-size: 34px;
    color: white;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: bold;
}

.logoText {
    font-size: 28px;
    color: white;
    font-weight: 300;
    padding-bottom: 20px;
}

.line{
    border-bottom: 1px solid #b8bac1;
    width: 80%;
    left: 10%;
    margin-left: 10%;
    margin-top: 17px;

}