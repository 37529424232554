.full_size {
    position: relative;
    display: 'inline-block'; 
    vertical-align: 'top';
}

@keyframes search {
    0% { margin-right: 30px; padding-left: 0px; }
    45% { margin-right: 0px; padding-left: 30px; }
    50% { margin-right: 0px; padding-left: 30px; }
    95% { margin-right: 30px; padding-left: 0px; }
    100% { margin-right: 30px; padding-left: 0px; }
}

.loading {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 20, 0.7);
    color: #FFF;
    z-index: 9999;
}

.loading div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.loading div svg {
    margin-right: 0px;
    padding-left: 30px;
    animation: search 2s infinite;
}
