
.title {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.language {
    text-align: center;
}

.flagBox {
    display: inline-block;
    margin: 4px 4px;
    border-radius: 5px;
    transition: 1s;
    cursor: pointer;
    transition: filter 1s;
}

.flagBox:hover {
    filter: grayscale(0%) !important;
}

.flagBox.fill {
    filter: grayscale(80%) ;
}

.flagBox.fill:hover {
    filter: grayscale(80%) ;
}

.flag {
    display: block;
    width: 30px;

    border-radius: 2px;
}