.option {
    position: relative;
    display: inline-block;
    margin: 3px 4px;
    padding: 4px 8px;
    font-weight: bold;
    cursor: pointer;
    background-color: #DDD;
    border: 1px solid #123;
    border-radius: 5px;
}

.option:hover {
    background-color: #CCC;
}

.helpText {
    color: red;
    font-weight: bold;
    margin: 3px 0px 6px 0px;
}

.myExpression {
    border-radius: 8px;
    padding: 4px;
}

.myExpressionRow {
    margin: 2px 0px;
    padding: 4px 8px;
    border: 1px solid #CCC;
    border-radius: 8px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.myExpressionRow div {
    min-height: 42px;
    line-height: 42px;
}

.myExpression button {
    font-size: 12px;
    line-height: 20px;
}

