  .carousel-space {
    overflow: hidden;
    position: absolute;
    left: 260px;
    width: calc(100% - 260px);
    top: 0;
  }

  .carousel-title {
    position: absolute;
    text-align: center;
    line-height: 200px;
    color: white;
    width: 100%;
    top: 0;
    font-weight: bold;
    font-size: 2rem;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  }

  .carousel-title-space {
    width: 100%;
    height: 200px !important;
    object-fit: cover;
    overflow: hidden;
  }

  .acordion-start {
    margin-top: 160px;
  }