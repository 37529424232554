.menu {
    background-color: #0e1420;
    top: 0;
    bottom:0;
    color: #8a9199;
    position: fixed;
    width: 260px !important;
    margin: auto;
    overflow-y:scroll;
    overflow-x:hidden;
    transition: right 0.5s;
 
}

a {
    display: inline-block;
    color: unset;
    text-decoration: none;
    overflow: hidden;
}

.submenu-show {
    font-size: 16px;
    width: 70%;
    margin-left: 20%;
    padding:  8px 26px 4px 8px;
    max-height: 36px;
    border-radius: 5px;
    transition: 0.2s;
    overflow: hidden;
}

.submenu-show svg {
    font-size: 16px;
}

.submenu-hide {
    max-height: 0px;
    padding: 0px;
    margin: 0px;
}

.submenu-hide a {
    height: 0px;
}

.outer-submenu-show {
    max-height: 100%;
    transition: 0.2s;
}

.outer-submenu-hide {
    max-height: 65px;
}

.menu::-webkit-scrollbar-track
{
	display: none;
}

.menu::-webkit-scrollbar
{
    display: none;

}

.menu::-webkit-scrollbar-thumb
{
    display: none;

}

.hide-menu
{
    right: -100% !important;
}

@media (max-width:991px) {
    .menu {
        width: 320px !important;
        right: 0;
    }
}