/* input {
  width: 300px !important;
  color: #000 !important;
  font-size: 12px;
  transition: 0.3s;
  display: inline-block;
border: 0px !important;

}
.uploadName{
  display: inline-block;
  width: 300px;;

}
.container{
  display: inline-block;
  vertical-align: middle;
}
.uploadIcon{
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
}
 */

.container {
  vertical-align: middle;
}

/* input {

  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;


} */

.inputfile+label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background: black !important;
  display: inline-block;
}

label.uploadLabel {
  background-color: #fff;
  color: #0e1420;
  vertical-align: middle;
  border: 2px solid #0e1420;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  width: 80%;
  margin-left: 10%;
  height: 200px;
  display: inline-block;
  text-align: center;
  align-items: center;
  line-height: 200px;
  font-weight: 900;
  font-size: 1.2rem;


}

.uploadButton {

  background-color: #0e1420;
  border-radius: 3px;
  width: 100px;
  margin-left: 5%;
  padding: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  display: inline-block;
  margin-left: calc((90%) - 100px);
  position: relative;
}

.uploadIcon {
  margin-right: 20px;
  display: inline-block;
  font-size: 18px;
  vertical-align: middle;
  cursor: pointer;
}

.card:hover {
  transform: scale(1.0) !important;
}

.uploadText {
  font-size: 14px;
  text-align: left;
  margin-top: 5px;
  margin-bottom: 15px;

}

.accordion {
  margin-top: 160px;
}