// box, offset, display, text
@each $size, $percent in $sizes {
    .box-#{$size} {
        display: inline-block;
        width: #{$percent};
        vertical-align: top;
    }

    .offset-#{$size} {
        margin-left: #{$percent};
    }

    @each $num, $px in $padmar {
        .box-#{$size}.px-#{$num}, .box-#{$size}.mx-#{$num} {
            width: calc(#{$percent} - #{$px} - #{$px});
        }

        .box-#{$size}.px-#{$num}.mx-#{$num} {
            width: calc(#{$percent} - #{$px} - #{$px} - #{$px} - #{$px});
        }

        .box-#{$size}.pl-#{$num}, .box-#{$size}.pr-#{$num}, .box-#{$size}.ml-#{$num}, .box-#{$size}.mr-#{$num} {
            width: calc(#{$percent} - #{$px});
        }

        .box-#{$size}.pl-#{$num}.pr-#{$num}, 
        .box-#{$size}.pl-#{$num}.ml-#{$num},
        .box-#{$size}.pl-#{$num}.mr-#{$num},
        .box-#{$size}.pr-#{$num}.ml-#{$num},
        .box-#{$size}.pr-#{$num}.mr-#{$num} {
            width: calc(#{$percent} - #{$px} - #{$px});
        }
    }
}

// display
@each $display in $displays {
    .#{$display} {
        display: #{$display};
    }
}

.default {
    display: inline-block;
}

// text align
@each $align in $aligns {
    .text-#{$align} {
        text-align: #{$align};
    }
}

// padding, margin
@each $num, $px in $padmar {
    // padding
    .px-#{$num} {
        padding-left: $px;
        padding-right: $px;
    }

    .py-#{$num} {
        padding-top: $px;
        padding-bottom: $px;
    }

    .pt-#{$num} {
        padding-top: $px;
    }

    .pb-#{$num} {
        padding-bottom: $px;
    }

    .pl-#{$num} {
        padding-left: $px;
    }

    .pr-#{$num} {
        padding-right: $px;
    }

    // margin
    .mx-#{$num} {
        margin-left: $px;
        margin-right: $px;
    }

    .my-#{$num} {
        margin-top: $px;
        margin-bottom: $px;
    }

    .mt-#{$num} {
        margin-top: $px;
    }

    .mb-#{$num} {
        margin-bottom: $px;
    }

    .ml-#{$num} {
        margin-left: $px;
    }

    .mr-#{$num} {
        margin-right: $px;
    }
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}