#mapInput{
    height: 23px !important;
    align-items: center !important;
    vertical-align: center !important;
    padding-bottom: 16.5px !important;
}

.google-visualization-tooltip-item {
    white-space: nowrap;
  }

  .filter-hidden{
    display: none;
  }