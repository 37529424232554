.searchTable {
    position: relative;
    width: 100%; /*calc(100% - 20px);*/
    padding: 10px 0px;
    margin: 10px 0px;
    border: 2px solid #CCC;
    box-shadow: 0px 2px 10px 0px #CCC;
    border-radius: 3px;
    overflow-x: auto;
}

.searchTable .hide {
    display: none;
}

.searchTable table {
    border-spacing: 0px;
    width: 100%;
}

.searchTable th {
    white-space: nowrap;
    padding: 10px;
    text-align: center;
}

.searchTable tr {
    background-color: #FFFFFF;
    color: #111111;
    transition: 0.3s;
}

.searchTable tr:nth-child(2n) {
    background-color: #EEEEEE;
}

.searchTable tr:hover {
    background-color: #F5F5F5;
    color: #333333;
    cursor: pointer;
}

.searchTable td {
    text-align: center;
    padding: 15px 10px;
    min-width: 150px;
}

.searchTable .controll {
    position: sticky;
    left: 0;
    margin-top: 10px;
}

.searchTable .delim {
    display: inline-block;
    padding: 0px 10px;
    font-size: 22px;
}

.searchTable .arrows {
    margin-left: 55px;
}

.searchTable .header svg {
    position: relative;
    top: 3px;
    margin-left: 5px;
}

@keyframes search {
    0% { margin-right: 30px; padding-left: 0px; }
    45% { margin-right: 0px; padding-left: 30px; }
    50% { margin-right: 0px; padding-left: 30px; }
    95% { margin-right: 30px; padding-left: 0px; }
    100% { margin-right: 30px; padding-left: 0px; }
}

.searchTable .loading {
    position: absolute;
    top: 48px;
    left: 0px;
    width: 100%;
    height: calc(100% - 48px);
    text-align: center;
    background-color: rgba(0, 0, 20, 0.7);
    color: #FFF;
}

.searchTable .loading div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.searchTable .loading div svg {
    margin-right: 0px;
    padding-left: 30px;
    animation: search 2s infinite;
}

.searchTable .controll select {
    width: 80px;
    margin-left: 30px;
    margin-right: 10px;
}

.filterRow {
    padding: 8px 0px;
    box-shadow: 0px 1px 0px #336;
}

.filterRow div {
    margin-top: 3px;
    margin-bottom: 3px;
}

.close {
    position: relative;
    top: 10px;
    right: 0px;
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 22px;
    font-size: 15px;
    font-weight: bold;
    color: #EE0033;
    border: 1px solid #EE0033;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.close svg {
    position: absolute;
    top: 4px;
    left: 4px;
}

.close:hover {
    color: #BB0033;
    border-color: #BB0033;
}

.filterItem {
    font-size: 16px;
    padding: 2px 6px;
    border-bottom: 1px solid #CCC;
}

.tooltip {
    font-size: 16px;
    text-align: right;
    padding: 1px 6px;
}

.filterName {
    display: inline-block;
    width: calc(100% - 70px);
}

.filterButtons {
    display: inline-block;
    width: 70px;
    text-align: right;
}

.filterButtons svg {
    margin-left: 12px;
}

.iconVisible, .iconFilter {
    color: #69C;
}

.iconInvisible, .iconNonFilter {
    color: #000;
}

.iconVisible:hover, .iconFilter:hover, .iconInvisible:hover, .iconNonFilter:hover {
    cursor: pointer;
    filter: drop-shadow(1px 1px 1px #999);
}

.noFilters {
    display: block;
    text-align: center;
    padding: 8px;
    font-size: 16px;
}

.filterBox {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(50% - 16px);
    padding: 8px;
    margin: 8px;
    text-align: center;
    background-color: #777;
    color: #FFF;
    padding: 8px 0px;
    border-radius: 4px;
}

.bigFilter {
    display: block;
    padding-bottom: 6px;
    font-size: 16px;
}

.filterClose {
    position: absolute;
    top: 4px;
    right: 4px;
    font-size: 18px;
    background-color: #333;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s;
    cursor: pointer;
}

.filterClose:hover {
    background-color: #555;
}

.remove, .edit, .share {
    position: absolute;
    bottom: auto;
    right: 5px;
    top: -15px;
    display: inline-block;
    vertical-align: top;
    width: 22px;
    height: 22px;
    font-size: 19px;
    font-weight: bold;
    color: #EE0033;
    border: 1px solid #EE0033;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    z-index: 5;
}

.remove svg, .edit svg, .share svg {
    position: absolute;
    top: 2px;
    left: 2px;
}

.remove:hover {
    color: #BB0033;
    border-color: #BB0033;
}

.edit {
    color: #112211;
    border-color: #112211;
    bottom: auto;
    right: 35px;
}

.share {
    color: #5b97d9;
    border-color: #5b97d9;
    bottom: auto;
    right: 65px;
}

.remove:hover {
    color: #BB0033;
    border-color: #BB0033;
}

.share:hover {
    color: #4285cf;
    border-color: #4285cf;
}

.iconBar {
    position: relative;
    display: 'inline-block'; 
    vertical-align: 'top';
    margin-top: 20px;
}