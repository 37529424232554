.box {
    position: relative;
    display: inline-block;
    vertical-align: top;
}

.menu-button {
    text-align: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 90%;
    left: 5%;
    padding: 12px 26px 12px 30px;
    border-radius: 10px;
    border: none;
    margin: 5px 0px;
    font-size: 16px;
    border: 1px solid #0e1420;

    color: #b8bac1;
    background-color: #0e1420;

    cursor: pointer;
    max-height: 100% !important;
    transition: 0.5s;
}

.usermenu-button {
    text-align: left;
    position: relative;
    display: inline-block;
    vertical-align: top;
   
    width: 90%;
    left: 5%;
    padding: 12px 26px 12px 30px;
    border-radius: 25px;
    border: none;
    margin: 5px 0px;
    font-size: 16px;
    border: 1px solid #0e1420;

    color: #b8bac1;
    background-color: #0e1420;

    cursor: pointer;
    max-height: 100% !important;
    transition: 0.5s;
    border: 1px solid white;
    margin-top: 50px;
}

.usermenu-button:hover {
    color: #FFF;
    font-weight: 300;
    background-color: #222836;
    border: 1px solid #b8bac1;
}

.menu-button:hover {
    color: #FFF;
    font-weight: 300;
    background-color: #222836;
    border: 1px solid #b8bac1;
}
.active-path > .menu-button {
    color: #FFF;
    font-weight: 300;
    background-color: #334264;
}

.menu-button svg , .usermenu-button svg {
 /*   top: 5px !important;*/
 margin-right: 5px;
}
.menu-button a{
    padding-top: 5px;
}
.menu-button.submenu-hide:hover{
    color: #b8bac1;
    font-weight: 300;
    background-color: #0e1420;
    border: 1px solid #0e1420;
}

.hr {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 14px;
    height: 15px;
    margin-bottom: 12px;
    font-weight: bold;
   
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.hr .hr-line {
    position: absolute;
    top: 15px;
    height: 2px;
    width: 100%;
}

.circle-button {
    position: relative;
    font-size: 28px;
    border: none;
    background-color: #0e1420;
    color: #EEE;
    padding: 0px 5px 3px 5px;
    border-radius: 25px;
    box-shadow: 0px 0px 5px 1px #CCC;
    cursor: pointer;
    margin-left: 10px;
}


.circle-button:hover {
    background-color: #555;
    color: #FFF;
    box-shadow: 0px 0px 5px 1px #FFF;
}
 
.button {
    padding: 6px 13px;
    border: none;
    background-color: #333;
    color: #FFF;
    font-size: 18px;
    margin: 0px 5px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.button:hover {
    background-color: #444;
    color: #EEE;
}

.button svg {
    position: relative;
   /* top: 3px;*/
   
}

.button span {
    display: inline-block;
} 
