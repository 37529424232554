.leaflet-container{
    width: 75vw;
    height: 75vh;
}

.custom-marker {
    width: 25px;
    height: 41px;
    background: transparent;
    border: none;
  }
  
  .marker-color {
    width: 100%;
    height: 100%;
    background-color: var(--marker-color, blue);
    border-radius: 50%;
  }

  .toggle-switch {
    width: 50px;
    height: 25px;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    background-color: red;  /* #BB9783*/
    transition: background-color 0.3s;    
  }
  
  .toggle-switch .slider {
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    position: absolute;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s;
  }

  .toggle-switch.checked {
    background-color: black;
  }
  
  .toggle-switch.checked .slider {
    transform: translateX(25px);
  }

  .toggle-switch:hover {
    background-color: grey; 
  }

  .marker-color {
    width: 100%;
    height: 100%;
    background-color: var(--marker-color, blue);
    border-radius: 50%;
  }
    
  .main-button-peds {
    background-color: aliceblue;
    color: black;
  }

  .main-button-zver {
    background-color: aliceblue;
    color: black;
  }

  #map {
    position: relative;
    z-index: 0;
    width: 100%;
  }