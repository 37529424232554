.top {

    color: #FFF;
}

.small-menu {
    position: fixed !important;
    top: 0px;
    right: -100% !important;
    background-color: #0e1420;
    transition: right 0.5s;
    z-index: 10;
}

.small-menu.show-menu {
    right: 0;
}