.page {
    background-color: #FFF;
    z-index: 0;
    left: 260px;
    width: calc(100% - 260px);
}

.page.border-radius {
    border-radius: 4px;
}

@media (min-width: 992px) {
    .page {
        border-radius: 6px;
    }
}