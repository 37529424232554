@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

body {
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', serif, Georgia, 'Times New Roman', Times, serif;
  font-size: 14px;
  background-color: #fff;
  color: #222;
}

p {
  margin: 0px;
}

.clickable {
  cursor: pointer;
}

/* INPUT */
input,
textarea {
  width: calc(100% - 30px);
  padding: 8px 13px;
  border: 2px solid #CCC;
  color: #555;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.3s;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0px 0px 5px 0px #00a2ff;
}

/* CHECKBOX */
input[type=checkbox] {
  width: 25px;
  /*position: relative;*/
  cursor: pointer;
}

input[type=checkbox]:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -2px;
  background-color: #FFFFFF;
  border: 2px solid #0AE;
  border-radius: 3px;
}

input[type=checkbox]:checked:before {
  background-color: #EEEEEE;
}

input[type=checkbox]:checked:after {
  content: "";
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-left: 3px;
  background-color: #0AE;
  border: 2px solid #0AE;
  border-radius: 3px;
}

input[type=checkbox]:focus {
  outline: none;
  box-shadow: none;
}

/* SELECT */
select {
  width: 100%;
  padding: 8px 45px 8px 13px;
  border: 2px solid #CCC;
  color: #555;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.3s;

  /* odstranit defaultnu sipku */
  display: inline-block;
  font: inherit;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* vytvorenie novej */
  background-image:
    linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position:
    calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px),
    calc(100% - 2.5em) 0.5em;
  background-size:
    5px 5px,
    5px 5px,
    1px 1.5em;
  background-repeat: no-repeat;
}

select option {
  color: #555555;
  background-color: #EEEEEE;
  font-size: 20px;
}

select:focus,
select:focus-visible {
  outline: none;
  box-shadow: 0px 0px 5px 0px #00a2ff;
}

/* CUSTOM SCROLLBAR */
/* ::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.4);
}

::-webkit-scrollbar-thumb {
  background-color: #3399CC;
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.3) 20%, transparent 20%, transparent 40%,
      rgba(255, 255, 255, 0.3) 40%,
      rgba(255, 255, 255, 0.3) 60%, transparent 60%, transparent 80%,
      rgba(255, 255, 255, 0.3) 80%);
  border-radius: 10px;
} */

::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	background-color: #000000;
	border: 2px solid #555555;
}



.regions-map {
  max-width: 800px;
}

.regions-map a polygon {
  fill: #DDF;
  stroke: #999;
  stroke-width: 4px;
}

.regions-map a.good polygon {
  fill: #88ff88;
}

.regions-map a.average polygon {
  fill: #ffe867;
}

.regions-map a.bad polygon {
  fill: #ffbe69;
}

.regions-map a.worst polygon {
  fill: #ff6a6a;
}

.regions-map a:hover polygon {
  fill: #EEF;
}

.regions-map a.good:hover polygon {
  fill: #57ff57;
}

.regions-map a.average:hover polygon {
  fill: #ffe344;
}

.regions-map a.bad:hover polygon {
  fill: #fcac44;
}

.regions-map a.worst:hover polygon {
  fill: #ff4747;
}

.regions-map a text {
  text-anchor: middle;
  fill: #335;
  font-size: 30px;
}

.regions-map a:hover text {
  fill: #004;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 15, 0.6);
  overflow-y: scroll;
  z-index: 10;
  opacity: 1.0;
  transition: 0.5s;
}

.modal.hide {
  opacity: 0.0;
  z-index: -1;
}

.modal-outer {
  position: absolute;
  display: inline-block;
  min-width: 250px;
  max-width: 90%;
  top: 15%;
  left: 50%;
  padding-bottom: 100px;
  transform: translateX(-50%);
}

.modal-inner {
  background-color: #FFF;
  padding: 25px;
  border-radius: 0px 35px 0px 35px;
  color: #113;
  box-shadow: 0px 0px 5px 0px #CCC;
  font-size: 16px;
}

.modal-title {
  position: relative;
  font-size: 22px;
}

.modal-title svg {
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
}

.modal-title svg:hover {
  color: #BB0033;
}

.modal-text {
  padding-top: 10px;
}

.card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
}

.accordion>.card:first-of-type {
  border-bottom: 1px !important;

}

.accordion>.card .card-header {
  margin-bottom: 0px !important;
}

.carousel-inner {
  width: 100%;
  height: 500px !important;
  object-fit: cover;
  overflow: hidden;
}

.carousel-space{
  position: absolute;
  left: 260px;
  width: calc(100% - 260px);
  top: 0;
}