.input-drop {
    width: calc(80% - 60px);
    padding: 60px 30px;
    font-size: 18px;
    text-align: center;
    border: 2px dashed #222;
    color: #222;
    cursor: pointer;
    margin: 5px auto;
}

.input-drop-files {
    font-weight: bold;
    color: #222;
}